export const BLOCK_NAMES = {
  accordion: 'ACCORDION_BLOCK',
  course: 'COURSE_BLOCK',
  ads: 'ADS_BLOCK',
  blackFriday: 'BLACK_FRIDAY_BLOCK',
  bigNumbers: 'BIG_NUMBERS_BLOCK',
  countdown: 'COUNT_DOWN_BLOCK',
  chatbotWhatsapp: 'CHATBOT_WHATSAPP_BLOCK',
  cta: 'CTA_BLOCK',
  custom: {
    default: 'CUSTOM_BLOCK',
    form: 'CUSTOM_FORM_BLOCK',
  },
  disclaimer: 'DISCLAIMER_BLOCK',
  bannerMNR: 'BANNER_MAO_NA_RODA_BLOCK',
  hero: {
    default: 'HERO_BLOCK',
    product: 'HERO_PRODUCT_BLOCK',
    withCta: 'HERO_WITH_CTA_BLOCK',
  },
  loan: {
    default: 'LOAN_SIMULATOR_BLOCK',
    fgts: 'FGTS_LOAN_SIMULATOR_BLOCK',
  },
  list: {
    default: 'LIST_BLOCK',
    features: 'LIST_FEATURES_BLOCK',
    medias: 'LIST_MEDIAS_BLOCK',
    panel: 'LIST_PANEL_BLOCK',
    blog: 'LIST_BLOG_BLOCK',
    news: 'LIST_NEWS_BLOCK',
    partner: 'LIST_PARTNER_BLOCK',
    searchPartner: 'LIST_SEARCH_PARTNER_BLOCK',
  },
  footer: 'FOOTER_BLOCK',
  textBlock: 'TEXT_BLOCK',
  plans: 'PLANS_BLOCK',
  media: {
    default: 'MIDIA_BLOCK',
    testimonial: 'MEDIA_TESTIMONIAL_BLOCK',
    product: 'MEDIA_PRODUCT_BLOCK',
    category: 'MEDIA_CATEGORY_BLOCK',
    list: 'MEDIA_LIST_BLOCK',
    features: 'MEDIA_FEATURES_BLOCK',
    productStepByStep: 'MEDIA_PRODUCT_STEP_BY_STEP_BLOCK',
    withBackground: 'MEDIA_WITH_BACKGROUND_BLOCK',
    productGuides: 'MEDIA_PRODUCT_GUIDES_BLOCK',
    cardList: 'MEDIA_CARD_LIST_BLOCK',
  },
  banner: 'BANNER_BLOCK',
  appInstallBanner: 'APP_INSTALL_BANNER',
  sectionAppInstall: 'SECTION_APP_INSTALL',
  videoLightBox: 'VIDEO_LIGHTBOX_BLOCK',
  discountCounter: 'DESCONTOMETRO_LNO_BLOCK',
  socialNetworks: 'SOCIAL_NETWORKS_BLOCK',
  sharingButtons: 'SHARING_BUTTONS_BLOCK',
  blog: {
    hero: 'BLOG_HERO_BLOCK',
    content: 'BLOG_CONTENT_BLOCK',
    video: 'BLOG_VIDEO_BLOCK',
    list: 'BLOG_LIST_BLOCK',
    iframe: 'BLOG_IFRAME_BLOCK',
    author: 'BLOG_AUTHOR_BLOCK',
    image: 'BLOG_IMAGE_BLOCK',
  },
  carousel: {
    product: 'PRODUCT_CAROUSEL_BLOCK',
    productSolution: 'PRODUCT_SOLUTION_CAROUSEL_BLOCK',
  },
  tabWithList: 'TAB_WITH_LIST_BLOCK',
  simpleAnnouncementBar: 'SIMPLE_ANNOUNCEMENT_BAR_BLOCK',
  persistentAnnouncementBar: 'PERSISTENT_ANNOUNCEMENT_BAR_BLOCK',
  table: 'TABLE_BLOCK',
  premium: {
    middleButton: 'MIDDLE_BUTTON_BLOCK',
    steps: 'STEPS_BLOCK',
    articlesGallery: 'ARTICLES_GALLERY_BLOCK',
    mediaWithForms: 'MEDIA_WITH_FORMS_BLOCK',
    heroProductPremium: 'HERO_PRODUCT_PREMIUM_BLOCK',
    list: 'PREMIUM_LIST_BLOCK',
    disclaimerPremium: 'DISCLAIMER_PREMIUM_BLOCK',
  },
}
