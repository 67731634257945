import { css, Global } from '@emotion/react'
import type { PropsWithChildren } from 'react'

import type { BuilderPageModelThemeData } from '@/legacy/models'
import { globalStyles, themeUtils } from '@/ui/shared/styles'

import { ThemeMetatags } from './ThemeMetatags'

import '@polycups/styles/dist/main.min.css'
import '@polycups/styles/dist/theme/theme-utils.min.css'

interface ThemeProps extends BuilderPageModelThemeData {}

function Theme({ data, children }: PropsWithChildren<ThemeProps>) {
  const themeData = data?.theme?.value?.data
  const themeKey = themeData?.key
  const themeName = `${themeKey || 'default'}-theme`
  const cssVars = themeUtils.createThemeVars({ colors: { ...(themeData?.colors || {}) } })

  return (
    <>
      {globalStyles}
      <ThemeMetatags themeKey={themeKey} {...themeData?.metaTags} />
      <div id="main-body" className={themeName}>
        <Global
          styles={css`
            :root,
            .${themeName} {
              ${cssVars}
            }
          `}
        />
        {children}
      </div>
    </>
  )
}

export default Theme
