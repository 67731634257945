import Head from 'next/head'

import { sanitizeUrl } from '@/legacy/core/utils/dom/security'

const BASE_HOST = process.env.NEXT_PUBLIC_ICONS_HOST
const BASE_URL = `${BASE_HOST}/lno/static-webfiles/common/products`

const appleSizes = [
  '57x57',
  '60x60',
  '72x72',
  '76x76',
  '114x114',
  '120x120',
  '144x144',
  '152x152',
  '180x180',
]

const genApple = (theme: string) =>
  appleSizes.map((sizes) => ({
    sizes,
    rel: 'apple-touch-icon',
    href: `${BASE_URL}/${theme}/apple-icon-${sizes}.png`,
  }))
const genAndroid = (theme: string) => ({
  sizes: '192x192',
  rel: 'icon',
  type: 'image/png',
  href: `${BASE_URL}/${theme}/android-icon-192x192.png`,
})
const genFavicon = (theme: string) =>
  ['32x32', '96x96', '16x16'].map((sizes) => ({
    sizes,
    rel: 'icon',
    type: 'image/png',
    href: `${BASE_URL}/${theme}/favicon-${sizes}.png`,
  }))

export const generateFavicon = (theme: string) => [
  genAndroid(theme),
  ...genApple(theme),
  ...genFavicon(theme),
]

export interface ThemeMetatagsProps {
  manifestJson: string
  themeColor: string
}

interface Props extends Partial<ThemeMetatagsProps> {
  themeKey: string | undefined
}

export function ThemeMetatags({ themeKey, manifestJson, themeColor }: Props) {
  const shouldUseDefaultFavicon =
    !themeKey || ['ecred', 'premium', 'carteira-digital'].includes(themeKey)

  const product = shouldUseDefaultFavicon ? 'serasa' : themeKey

  const urlManifestJson =
    manifestJson || `/lno/static-webfiles/common/products/${product}/manifest.json`

  return (
    <Head>
      <link
        rel="manifest"
        href={sanitizeUrl(
          urlManifestJson.includes('https') ? urlManifestJson : `${BASE_HOST}${urlManifestJson}`,
        )}
      />
      <meta name="theme-color" content={themeColor || '#e63887'} />
      {generateFavicon(product).map((faviconProps, index) => (
        <link key={`m-fav-${index}`} {...faviconProps} />
      ))}
    </Head>
  )
}
