import { CONFIG } from '@/ui/shared/styles'

export const MIDDLE_BUTTON_BACKGROUND_VARIANTS = {
  snowGray: 'snowGray',
  graySnow: 'graySnow',
  gray: 'gray',
  snow: 'snow',
} as const

export const MIDDLE_BUTTON_BACKGROUND_COLORS = {
  snowGray: {
    firstHalf: CONFIG.theme.colors.snow,
    secondHalf: CONFIG.theme.colors.palegray,
  },
  graySnow: {
    firstHalf: CONFIG.theme.colors.palegray,
    secondHalf: CONFIG.theme.colors.snow,
  },
  gray: {
    firstHalf: CONFIG.theme.colors.palegray,
    secondHalf: CONFIG.theme.colors.palegray,
  },
  snow: {
    firstHalf: CONFIG.theme.colors.snow,
    secondHalf: CONFIG.theme.colors.snow,
  },
} as const
