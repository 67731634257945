import type { Input } from '@builder.io/sdk'

import { imageBuilderInput } from '@/legacy/components/Media/constants.register'

export const bannerBuilderInputs: Input[] = [
  {
    name: 'link',
    type: 'object',
    required: true,
    subFields: [
      {
        name: 'href',
        type: 'string',
        friendlyName: 'URL',
        required: true,
      },
      {
        name: 'label',
        type: 'string',
        friendlyName: 'Texto',
        required: true,
      },
    ],
  },
  {
    name: 'images',
    type: 'object',
    required: true,
    subFields: [
      {
        ...imageBuilderInput,
        name: 'desktop',
        friendlyName: 'Imagem desktop',
        required: true,
        // @TODO BUG BUILDER - não aceita showIf em subFields
        showIf: undefined,
      },
      {
        ...imageBuilderInput,
        name: 'mobile',
        friendlyName: 'Imagem mobile',
        required: true,
        // @TODO BUG BUILDER - não aceita showIf em subFields
        showIf: undefined,
      },
    ],
  },
]
