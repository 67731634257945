import type { Input } from '@builder.io/sdk'

export const listLayoutBuilderInput: Input = {
  name: 'layout',
  type: 'string',
  friendlyName: 'Tipo de Lista',
  hideFromUI: true,
}

export const LIST_LAYOUT = {
  list: 'LIST_BLOCK',
  blog: 'LIST_BLOG_BLOCK',
  feature: 'LIST_FEATURES_BLOCK',
  news: 'LIST_NEWS_BLOCK',
  medias: 'LIST_MEDIAS_BLOCK',
  panel: 'LIST_PANEL_BLOCK',
  partner: 'LIST_PARTNER_BLOCK',
} as const

export type ListLayoutKeys = (typeof LIST_LAYOUT)[keyof typeof LIST_LAYOUT]
