import type { Input } from '@builder.io/sdk'

import {
  containerBackgroundColorBuilderInput,
  containerBackgroundColorHexBuilderInput,
  containerSpaceInBuilderInput,
  containerSpaceOutBuilderInput,
} from '@/legacy/components/Container'

import type { TypographyColorModifier } from '@/legacy/components/Typography'

import {
  colorModifierBuilderInput,
  fontSizeBuilderInput,
  heroSubtitleBuilderInput,
  heroTitleBuilderInput,
  idBuilderInput,
  positionBuilderInput,
  subtitleBuilderInput,
  titleBuilderInput,
  verticalPositionInput,
  verticalSpacing,
} from './constants'

export const builderInputsBlock = ({
  withBackground = true,
  withFontSize = true,
  withPosition = true,
  withContainerSpaceIn = false,
  withContainerSpaceOut = true,
  withTitle = true,
  withSubtitle = true,
  isHero = false,
  hasAdvancedOptions = false,
}: Partial<
  Record<
    | 'withBackground'
    | 'withFontSize'
    | 'withPosition'
    | 'isHero'
    | 'withContainerSpaceIn'
    | 'withContainerSpaceOut'
    | 'withTitle'
    | 'hasAdvancedOptions'
    | 'withSubtitle',
    boolean
  >
> = {}): Input[] => {
  let inputs: Input[] = [{ ...idBuilderInput, required: false, advanced: hasAdvancedOptions }]

  if (isHero) {
    if (withTitle) {
      inputs = [...inputs, { ...heroTitleBuilderInput, required: false }]
    }
    if (withSubtitle) {
      inputs = [...inputs, { ...heroSubtitleBuilderInput, required: false }]
    }
  } else {
    if (withTitle) {
      inputs = [...inputs, { ...titleBuilderInput, required: false }]
    }
    if (withSubtitle) {
      inputs = [...inputs, { ...subtitleBuilderInput, required: false }]
    }
  }

  if (withFontSize) {
    inputs = [...inputs, { ...fontSizeBuilderInput, advanced: hasAdvancedOptions }]
  }

  if (withPosition) {
    inputs = [...inputs, { ...positionBuilderInput, advanced: hasAdvancedOptions }]
  }

  if (withBackground) {
    inputs = [
      ...inputs,
      {
        ...containerBackgroundColorBuilderInput,
        name: 'background',
        advanced: hasAdvancedOptions,
        enum: [
          { label: 'Cinza', value: 'palegray' },
          { label: 'Branco', value: 'snow' },
        ],
      },
    ]
  }

  if (withContainerSpaceIn) {
    inputs = [
      ...inputs,
      {
        ...containerSpaceInBuilderInput,
        advanced: hasAdvancedOptions,
        name: 'spaceIn',
      },
    ]
  }

  if (withContainerSpaceOut) {
    inputs = [
      ...inputs,
      {
        ...containerSpaceOutBuilderInput,
        name: 'space',
        advanced: hasAdvancedOptions,
      },
    ]
  }

  return inputs
}

export const builderInputsBlockWithBackgroundHex = ({
  withContainerSpaceOut = true,
  withContainerSpaceIn = false,
  withPosition = true,
  withFontSize = true,
  withTitle = true,
  withSubtitle = true,
  isHero = false,
  hasAdvancedOptions = false,
}: Partial<
  Record<
    | 'withContainerSpaceOut'
    | 'withPosition'
    | 'withFontSize'
    | 'withTitle'
    | 'withSubtitle'
    | 'isHero'
    | 'hasAdvancedOptions'
    | 'withContainerSpaceIn',
    boolean
  >
> = {}) => {
  let inputs: Input[] = [{ ...idBuilderInput, required: false, advanced: hasAdvancedOptions }]

  if (withTitle) {
    if (isHero) {
      inputs = [
        ...inputs,
        {
          ...heroTitleBuilderInput,
          required: false,
        },
      ]
    } else {
      inputs = [...inputs, { ...titleBuilderInput, required: false }]
    }
  }

  if (withSubtitle) {
    if (isHero) {
      inputs = [
        ...inputs,
        {
          ...heroSubtitleBuilderInput,
          required: false,
        },
      ]
    } else {
      inputs = [...inputs, { ...subtitleBuilderInput, required: false }]
    }
  }

  if (withFontSize) {
    inputs = [...inputs, { ...fontSizeBuilderInput, advanced: hasAdvancedOptions }]
  }

  if (withPosition) {
    inputs = [...inputs, { ...positionBuilderInput, advanced: hasAdvancedOptions }]
  }

  inputs = [
    ...inputs,
    {
      ...containerBackgroundColorBuilderInput,
      name: 'background',
      advanced: hasAdvancedOptions,
    },
    {
      ...containerBackgroundColorHexBuilderInput,
      name: 'backgroundColor',
      advanced: hasAdvancedOptions,
      showIf: (options) => options.get('background') === 'custom',
    },
    { ...colorModifierBuilderInput, advanced: hasAdvancedOptions },
  ]

  if (withContainerSpaceOut) {
    inputs = [
      ...inputs,
      {
        ...containerSpaceOutBuilderInput,
        name: 'space',
        advanced: hasAdvancedOptions,
      },
    ]
  }

  if (withContainerSpaceIn) {
    inputs = [
      ...inputs,
      {
        ...containerSpaceInBuilderInput,
        name: 'spaceIn',
        advanced: hasAdvancedOptions,
      },
    ]
  }

  return inputs
}

export const builderLightText = (colorModifier?: TypographyColorModifier) =>
  (colorModifier === 'light' && 'solid') || undefined

export const verticalPositionBuilderInputs = (hasAdvancedOptions?: boolean) => {
  const shouldBeAdvancedOptions = { advanced: hasAdvancedOptions }
  const verticalPositionInputs = [
    { ...shouldBeAdvancedOptions, ...verticalPositionInput },
    { ...shouldBeAdvancedOptions, ...verticalSpacing },
  ]
  return verticalPositionInputs
}
