import styled from '@emotion/styled'

import { Image, type MedusaImageData } from '@/legacy/components/Image'
import { decodeURL } from '@/legacy/core/utils/dom/decodeURL'
import { useIsMobile } from '@/legacy/hooks/useIsMobile'
import { CONFIG } from '@/ui/shared/styles'

export interface BannerProps {
  link: {
    href?: string
    label?: string
  }
  images: {
    desktop: MedusaImageData
    mobile: MedusaImageData
  }
}

const BannerStyles = styled.a({
  display: 'flex',
  position: 'relative',
  maxHeight: '23rem',
  maxWidth: '20.5rem',
  width: 'auto',
  margin: 'auto',
  justifyContent: 'center',

  [CONFIG.media.lg]: {
    maxWidth: 'none',
  },
})

const Banner = ({ images, link }: BannerProps) => {
  const isMobile = useIsMobile()
  const alt = (images && (images.desktop?.alt || images.mobile?.alt)) || 'banner de oferta'

  return (
    <BannerStyles href={decodeURL(link?.href)} className="a" title={link?.label}>
      {isMobile && images?.mobile?.url && (
        <Image data={images.mobile} alt={alt} layout="fixed" height="368" width="328" />
      )}
      {!isMobile && images?.desktop?.url && (
        <Image data={images.desktop} alt={alt} layout="fixed" height="264" width="928" />
      )}
    </BannerStyles>
  )
}

export default Banner
