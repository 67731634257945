import styled from '@emotion/styled'
import cx from 'classnames'
import type { PropsWithChildren } from 'react'

export type Space = 's' | 'm' | 'l' | '0' | '1' | '2' | '3'
export type BackgroundColor = 'gray' | 'palegray' | 'snow' | 'accent' | 'primary' | 'custom'
export type ContainerMaxWidth = 'md' | 'lg' | 'xg'
export type VerticalPosition = 'bottom' | 'top'
export type VerticalSpacing = 'sm' | 'lg'

export interface ContainerProps {
  id?: string
  className?: string
  contentClassName?: string
  backgroundColor?: BackgroundColor
  backgroundColorHex?: string
  spaceOut?: Space
  spaceIn?: Space
  maxWidth?: ContainerMaxWidth
}

const maxWidths = {
  xg: '1360px',
  lg: '1044px',
  md: '960px',
}

const ContainerStyles = styled.div<{ maxWidth: string }>(
  (props) => `
    max-width: ${props.maxWidth};
  `,
)

const Container = ({
  id,
  className,
  backgroundColor,
  backgroundColorHex,
  children,
  spaceOut,
  spaceIn,
  contentClassName,
  maxWidth = 'md',
}: PropsWithChildren<ContainerProps>) => (
  <div
    style={{
      backgroundColor: (backgroundColor === 'custom' && backgroundColorHex) || undefined,
    }}
    className={cx(className, {
      [`et-bg-${backgroundColor}`]: backgroundColor !== 'custom' && backgroundColor,
      [`eu-sp-${spaceIn}`]: spaceIn,
      [`eu-sm-${spaceOut}`]: spaceOut,
    })}
  >
    <ContainerStyles
      id={id}
      maxWidth={maxWidths[maxWidth]}
      className={cx('ecs-container', contentClassName)}
    >
      {children}
    </ContainerStyles>
  </div>
)

export default Container
