import { builder } from '@builder.io/sdk'

import type { IncomingMessage, ServerResponse } from 'http'

import { sendError } from '@/legacy/core/error'

import { BUILDER_CONTENT_API_URL, BUILDER_SERASA_PUBLIC_KEY } from './constants'

export const getUrlContentApi = (model: string) =>
  `${BUILDER_CONTENT_API_URL}/${model}?apiKey=${BUILDER_SERASA_PUBLIC_KEY}`

export const initBuilder = ({ req, res }: { req?: IncomingMessage; res?: ServerResponse } = {}) => {
  try {
    builder.init(BUILDER_SERASA_PUBLIC_KEY, undefined, req, res)
  } catch (error) {
    sendError({ error })
  }
}
