import { currencyToNumber } from '@/legacy/core/utils/currency'

export const SIMULATION_FORM_STEP = 'simulationForm'
export const SIMULATION_RESULT_STEP = 'simulationResult'

export const SIMULATION_RESULT = {
  title: 'Sua simulação',
  amoutLabel: 'Você irá receber aproximadamente*',
  disclaimer:
    '*Contratação sujeita a análise de crédito. Os valores apresentados na simulação são aproximados e não incluem tributos. Para visualizar a sua oferta personalizada clique no botão "antecipar agora" e aguarde a aprovação.',
  cta: 'https://www.serasa.com.br/entrar?product=ecred&redirectUrl=%2Fecred%2Farea-cliente%2Fredirect-resolve%3Fr%3Dpay-debts%26v%3D3000%26p%3D12%26utm_source%3Demprestimo&utm_source=emprestimo',
} as const

export const ACCESSIBLE_DISCLAIMER = SIMULATION_RESULT.disclaimer.slice(1)

export const makeCurrencyAccessible = (amount: string): string => {
  const valueWithNoCipher = currencyToNumber(amount).toFixed(2)
  const reaisAndCentsSplitted = valueWithNoCipher.toString().split('.')

  if (reaisAndCentsSplitted.length === 1) return `${reaisAndCentsSplitted[0]} reais`
  return `${reaisAndCentsSplitted[0]} reais e ${reaisAndCentsSplitted[1]} centavos`
}

export const getSwitchLabel = (hasFgts: boolean): string =>
  hasFgts
    ? 'Não sei o valor que eu tenho no FGTS. Desmarque esta caixa caso queira simular com o valor do salário'
    : 'Sei o valor que eu tenho no FGTS. Marque esta caixa caso queira simular com o valor do FGTS'
