import type { Input } from '@builder.io/sdk'

import {
  customEventActionInput,
  customEventContentTypeInput,
  customEventItemIdInput,
  customEventItemTextInput,
} from '@/legacy/core/analytics/utils/builder-inputs'

export const LAYOUT_COMPONENT = 'acf_fc_layout'

export interface ButtonEventProps {
  itemId?: string
  itemText?: string
  action?: string
  content_type?: string
}

export type CustomAnalyticsAttributes = {
  viewContentEvent: { itemText?: string; action?: string }
  buttonEvent: ButtonEventProps
}

export const buttonEvent = {
  name: 'buttonEvent',
  type: 'object',
  subFields: [
    customEventItemIdInput,
    customEventItemTextInput,
    customEventActionInput,
    customEventContentTypeInput,
  ],
}

const viewContentEvent = {
  name: 'viewContentEvent',
  type: 'object',
  subFields: [customEventItemTextInput, customEventActionInput],
}

export const analytics: Input = {
  name: 'analytics',
  type: 'object',
  advanced: true,
  subFields: [buttonEvent, viewContentEvent],
}

export const titleTextBuilderInputSubFields = {
  name: 'text',
  type: 'string',
  friendlyName: 'Texto',
}

export const titleTagBuilderInputSubFields = {
  name: 'tag',
  type: 'string',
  defaultValue: 'h3',
  enum: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'span', 'strong', 'div', 'p'],
}

export const titleBuilderInputSubFields: Input[] = [
  titleTextBuilderInputSubFields,
  titleTagBuilderInputSubFields,
]

const baseTitleBuilderInput = {
  name: 'title',
  type: 'object',
  friendlyName: 'Título',
  subFields: [titleTextBuilderInputSubFields, titleTagBuilderInputSubFields],
}

export const heroTitleBuilderInput = {
  ...baseTitleBuilderInput,
  defaultValue: {
    text: 'Título',
    tag: 'h1',
  },
}

export const titleBuilderInput: Input = {
  ...baseTitleBuilderInput,
  defaultValue: {
    text: 'Título',
    tag: 'h3',
  },
}

const baseSubtitleBuilderInput = {
  name: 'subtitle',
  type: 'object',
  friendlyName: 'Subtítulo',
  subFields: [titleTextBuilderInputSubFields, titleTagBuilderInputSubFields],
}

export const heroSubtitleBuilderInput = {
  ...baseSubtitleBuilderInput,
  defaultValue: {
    text: 'Subtítulo',
    tag: 'h2',
  },
}

export const subtitleBuilderInput: Input = {
  ...baseSubtitleBuilderInput,
  defaultValue: {
    text: 'Subtítulo',
    tag: 'h4',
  },
}

export const idBuilderInput: Input = {
  name: 'id',
  type: 'string',
  friendlyName: 'ID elemento',
}

export const colorModifierBuilderInput: Input = {
  name: 'colorModifier',
  type: 'string',
  friendlyName: 'Cor da fonte',
  defaultValue: 'dark',
  enum: [
    { label: 'Clara', value: 'light' },
    { label: 'Escura', value: 'dark' },
  ],
}

export const fontSizeBuilderInput: Input = {
  name: 'size',
  type: 'string',
  friendlyName: 'Tamanho dos títulos',
  defaultValue: 'l',
  enum: [
    {
      value: 'm',
      label: 'Médio',
    },
    {
      value: 'l',
      label: 'Grande',
    },
  ],
}

export const positionBuilderInput: Input = {
  name: 'position',
  type: 'string',
  friendlyName: 'Posição do conteúdo',
  defaultValue: 'left',
  enum: [
    { value: 'left', label: 'Esquerda' },
    { value: 'center', label: 'Centro' },
    { value: 'right', label: 'Direita' },
  ],
}

export const LIST_SIZE = {
  md: 'm',
  lg: 'l',
} as const

export const hasButtonBuilderInput: Input = {
  name: 'hasButton',
  type: 'boolean',
  friendlyName: 'Exibir botão',
  defaultValue: true,
  required: true,
}

export const hasButtonBuilderInputs: Input[] = [
  hasButtonBuilderInput,
  {
    name: 'buttonLabel',
    type: 'string',
    defaultValue: 'Clique aqui',
    friendlyName: 'Botão - Texto',
    showIf: (options) => options.get('hasButton'),
  },
  {
    name: 'buttonType',
    type: 'string',
    friendlyName: 'Botão - Ação customizada',
    defaultValue: 'link',
    showIf: (options) => options.get('hasButton'),
    enum: [
      { value: 'sem ação', label: 'Sem ação' },
      { value: 'link', label: 'Link' },
      { value: 'scroll', label: 'Rolagem' },
      { value: 'modal', label: 'Modal' },
    ],
  },
  {
    name: 'buttonValue',
    type: 'string',
    friendlyName: 'Botão - Link URL',
    showIf: (options) => options.get('hasButton') && options.get('buttonType') === 'link',
  },
  {
    name: 'buttonValue',
    type: 'string',
    friendlyName: 'Botão - Ação de scroll (ID elemento)',
    showIf: (options) => options.get('hasButton') && options.get('buttonType') === 'scroll',
  },
  {
    name: 'buttonValue',
    type: 'string',
    friendlyName: 'ID do Modal',
    showIf: (options) => options.get('hasButton') && options.get('buttonType') === 'modal',
  },
]

export const buttonAriaLabelBuilderInput = {
  name: 'buttonAriaLabel',
  type: 'string',
  friendlyName: 'Botão - Descritivo pro leitor de tela',
}

export const buttonCustomGALabelBuilderInput = {
  name: 'buttonCustomGALabel',
  type: 'string',
  friendlyName: 'Botão - Rótulo de GA customizado',
}

export const verticalPositionInput = {
  name: 'verticalPosition',
  friendlyName: 'Posicionamento vertical',
  type: 'string',
  defaultValue: 'bottom',
  enum: [
    { label: 'Inferior', value: 'bottom' },
    { label: 'Superior', value: 'top' },
  ],
}

export const verticalSpacing = {
  name: 'bottomSpacing',
  friendlyName: 'Espaçamento vertical',
  type: 'string',
  defaultValue: 'sm',
  enum: [
    { label: 'Pequeno (8px)', value: 'sm' },
    { label: 'Grande (Mobile 136px, Desktop 124px)', value: 'lg' },
  ],
}
