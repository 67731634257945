import type { Input } from '@builder.io/sdk'

export const customEventItemIdInput: Input = {
  name: 'itemId',
  type: 'string',
}

export const customEventItemTextInput: Input = {
  name: 'itemText',
  type: 'string',
}

export const customEventActionInput: Input = {
  name: 'action',
  type: 'string',
}
export const customEventContentTypeInput: Input = {
  name: 'content_type',
  type: 'string',
}
