import type { Input } from '@builder.io/sdk'

export const containerSpaces = [
  { value: '0', label: 'Sem espaçamento' },
  { value: '1', label: 'Espaço - 1' },
  { value: '2', label: 'Espaço - 2' },
  { value: '3', label: 'Espaço - 3' },
  { value: 's', label: 'Pequeno' },
  { value: 'm', label: 'Médio' },
  { value: 'l', label: 'Grande' },
]

export const containerSpaceOutBuilderInput: Input = {
  name: 'spaceOut',
  type: 'string',
  friendlyName: 'Espaçamento externo',
  defaultValue: 'm',
  enum: containerSpaces,
}

export const containerSpaceInBuilderInput: Input = {
  name: 'spaceIn',
  type: 'string',
  friendlyName: 'Espaçamento interno',
  defaultValue: 'm',
  enum: containerSpaces,
}

export const containerBackgroundColors = [
  { value: 'gray', label: 'Cinza' },
  { value: 'snow', label: 'Branco' },
  { value: 'primary', label: 'Primário' },
]

export const containerBackgroundColorBuilderInput: Input = {
  name: 'backgroundColor',
  type: 'string',
  friendlyName: 'Cor de fundo',
  defaultValue: 'snow',
  enum: [
    ...containerBackgroundColors,
    {
      value: 'custom',
      label: 'Customizada',
    },
  ],
}

export const containerBackgroundColorHexBuilderInput: Input = {
  name: 'backgroundColorHex',
  type: 'color',
  friendlyName: 'Cor de fundo customizada',
  showIf: (options) => options.get(containerBackgroundColorBuilderInput.name) === 'custom',
}

export const containerBackgroundColorBuilderInputs: Input[] = [
  containerBackgroundColorBuilderInput,
  containerBackgroundColorHexBuilderInput,
]
