import type { Input } from '@builder.io/sdk'

import { idBuilderInput } from '@/legacy/blocks/constants'

export const midiaTypeBuilderInput: Input = {
  name: 'type',
  type: 'string',
  friendlyName: 'Tipo de midia',
  defaultValue: 'image',
  enum: [
    { value: 'video', label: 'Vídeo' },
    { value: 'image', label: 'Imagem' },
  ],
}

const imageBuilderInputSubfields = [
  {
    name: 'url',
    type: 'file',
    friendlyName: 'Arquivo',
    allowedFileTypes: ['svg', 'png', 'gif', 'jpg', 'jpeg'],
  },
  {
    name: 'alt',
    type: 'longText',
    friendlyName: 'Alt',
  },
  {
    name: 'layout',
    type: 'string',
    friendlyName: 'Layout',
    defaultValue: 'responsive',
    hideFromUI: true,
    enum: ['intrinsic', 'fixed', 'responsive', 'fill'],
  },
  {
    name: 'description',
    type: 'string',
    hideFromUI: true,
  },
  {
    name: 'mime_type',
    type: 'string',
    hideFromUI: true,
  },
  {
    name: 'sizes',
    type: 'object',
    hideFromUI: true,
    subFields: [
      {
        name: 'medium',
        type: 'string',
      },
      {
        name: 'large',
        type: 'string',
      },
    ],
  },
]

export const imageWithAriaFlagBuilderInput: Input = {
  name: 'image',
  type: 'object',
  friendlyName: 'Imagem',
  defaultValue: {
    layout: 'responsive',
    'aria-hidden': false,
  },
  subFields: [
    ...imageBuilderInputSubfields,
    {
      name: 'aria-hidden',
      type: 'boolean',
      friendlyName: 'Esconder de Leitores de Tela',
    },
  ],
}

export const imageBuilderInput: Input = {
  name: 'image',
  type: 'object',
  friendlyName: 'Imagem',
  defaultValue: {
    layout: 'responsive',
    'aria-hidden': false,
  },
  subFields: [
    ...imageBuilderInputSubfields,
    {
      name: 'aria-hidden',
      type: 'boolean',
      friendlyName: 'Esconder de Leitores de Tela',
      hideFromUI: true,
    },
  ],
}

export const videoBuilderInput: Input = {
  name: 'video',
  type: 'object',
  friendlyName: 'Vídeo',
  subFields: [
    {
      ...idBuilderInput,
      hideFromUI: true,
    },
    {
      name: 'url',
      type: 'string',
      friendlyName: 'ID',
      helperText: 'Somente URL ou ID do vídeo do YouTube.',
    },
  ],
}

export const sizesBuilderInputs: Input[] = [
  {
    name: 'width',
    type: 'string',
    friendlyName: 'Largura',
  },
  {
    name: 'height',
    type: 'string',
    friendlyName: 'Altura',
  },
]

export const midiaBuilderInput: Input = {
  name: 'midia',
  type: 'object',
  friendlyName: 'Mídia',
  defaultValue: {
    type: 'image',
  },
  subFields: [
    midiaTypeBuilderInput,
    {
      ...imageBuilderInput,
      // @TODO Bug Showif in Subfield Builder
      showIf: undefined,
    },

    {
      ...videoBuilderInput,
      // @TODO Bug Showif in Subfield Builder
      showIf: undefined,
    },
  ],
}

export const midiaImageBuilderInput: Input = {
  ...midiaBuilderInput,
  subFields: [
    {
      ...midiaTypeBuilderInput,
      defaultValue: 'image',
      hideFromUI: true,
    },
    {
      ...imageBuilderInput,
      // @TODO BUG BUILDER - não aceita showIf em subFields
      showIf: undefined,
    },
  ],
}
